import React from 'react';
import {Trans} from "gatsby-plugin-react-i18next";
import ReactVideo from "../video-player/ReactVideo";

const CustomerVideoWithList = ({ title, list, vidURL, poster }) => {
  const handleVideoEnd = (player) => {
    player.currentTime = 0;
    player.load();
  }

  return(
      <div className="container">
        <div className={'grid-container'}>
          <h2><Trans>{title}</Trans></h2>
          <ul>
            {
              list.map((items, i) => {
                return (
                    <li key={i}><Trans>{items}</Trans></li>
                )
              })
            }
          </ul>
        </div>
        <div className="video-holder">
          <ReactVideo
              vURL={vidURL}
              poster={poster} />
        </div>
      </div>
  )
}
export default CustomerVideoWithList;